import * as React from "react"
import { graphql, Link } from "gatsby"

const TestPage = ({ data }) => {
  const allPosts = data.allEcsPage.edges.map(({ node }) => node)

  return (
    <>
      <p>Hi people</p>
      <p>Here are my ecs content pages</p>
      <ol>
        {allPosts.map((p, index) => (
          <li key={index}>
            <Link to={`/${p.slug}`}>{p.slug}</Link>
          </li>
        ))}
      </ol>
    </>
  )
}

export default TestPage

export const query = graphql`
  query {
    allEcsPage {
      edges {
        node {
          id
          slug
        }
      }
    }
  }
`
